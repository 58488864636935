import React from 'react';
import { animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';
import styles from './infinite-carousel.module.scss';

const InfiniteCarousel = ({ items, Component, ...props }) => {
	const animation = useSpring({
		from: { transform: 'translate3d(0%, 0, 0)' },
		to: async next => {
			while (true) {
				await next({ transform: 'translate3d(-50%, 0, 0)' });
			}
		},
		config: {
			duration: 2400 * items.length
		},
		reset: true
	});

	return (
		<animated.div className={styles.carousel} style={animation}>
			{items.map((item, i) => (
				<Component key={i} {...item} {...props} />
			))}
			{items.map((item, i) => (
				<Component key={i} {...item} {...props} />
			))}
		</animated.div>
	);
};

InfiniteCarousel.propTypes = {
	items: PropTypes.array.isRequired,
	Component: PropTypes.any.isRequired
};

export default InfiniteCarousel;
